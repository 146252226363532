import CanvasView from '~/components/canvas-view/canvas-view'
import styles from '~/components/completed-view/completed-view.module.scss'
import { Component, Show, createEffect, createSignal } from 'solid-js'
import { CompletedViewProps } from '~/components/completed-view/completed-view.interfaces'
import { chapter, getChapterData, setChapter } from '~/services/current-document/chapter'
import { getTranslations } from '~/services/database/translations'
import { useNavigate } from '@solidjs/router'
import { createInitialTypesetTexts } from '~/services/current-document/loading/initial-typeset-texts'
import { getTypesetTexts } from '~/services/database/typeset-texts'
import { loadTypesetTexts } from '~/services/current-document/loading/typeset-texts'
import { loadTranslations } from '~/services/current-document/loading/translations'
import { DocumentProps } from '~/editor/document/document.interfaces'
import Document from '~/editor/document/document'
import { getComments } from '~/services/database/comments'
import SidebarHeader from '~/components/sidebar-header/sidebar-header'
import ExportModal from '~/components/export-modal/export-modal'
import { translations } from '~/services/current-document/translations'
import { typesetTexts } from '~/services/current-document/typeset-texts'
import Button from '~/components/button/button'
import ExportIcon from '~/assets/icons/export.svg'
import { setLoadingState } from '~/services/loading/loading'

const CompletedView: Component<CompletedViewProps> = ({ chapterId }) => {
  const [isTranslationViewLoading, setTranslationViewLoading] = createSignal(true)
  const [isCompletedViewLoading, setCompletedViewLoading] = createSignal(true)
  const [showExportModal, setExportModal] = createSignal(false)
  const [translationDocument, setTranslationDocument] = createSignal<Document>()
  const [typesettingDocument, setTypesettingDocument] = createSignal<Document>()
  const navigate = useNavigate()

  // Scroll Sync
  createEffect(() => {
    const doc1 = typesettingDocument()
    const doc2 = translationDocument()
    if(doc1 && doc2){
      doc1.linkedDocument = doc2
      doc2.linkedDocument = doc1
    }
  })

  // Link Translations <> TypesettingTexts when both are ready
  createEffect(() => {
    if (!isTranslationViewLoading() && !isCompletedViewLoading()){
      typesetTexts().forEach(typesetText => {
        typesetText.translation = translations().find(translation => translation.id === typesetText.translationId) ?? null
        if(typesetText.translation && !typesetText.translation.typesetText){
          typesetText.translation.typesetText = typesetText
        }
      })
    }
  })

  const onTypesettingDocumentReady = async (props: DocumentProps) => {
    const typesettingDocument = new Document(props)
    setTypesettingDocument(typesettingDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const typesetTextsData = await getTypesetTexts(chapterId)

    const cleanPagesExist = chapterData.pages.clean.length > 0
    const typesetTextsExist = typesetTextsData.length > 0
    if (!cleanPagesExist) {
      navigate(`/series/chapter/${chapterId}/upload`)
    } else {
      if (typesetTextsExist) {
        loadTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          typesetTextsData,
          setIsLoading: setCompletedViewLoading
        })
      } else {
        createInitialTypesetTexts({
          currentDocument: typesettingDocument,
          chapterData,
          translationsData,
          setIsLoading: setCompletedViewLoading
        })
      }
    }

    return typesettingDocument
  }

  const onTranslationDocumentReady = async (props: DocumentProps) => {
    const translationDocument = new Document(props)
    setTranslationDocument(translationDocument)
    const chapterData = await getChapterData(chapterId)
    setChapter(chapterData)
    const translationsData = await getTranslations(chapterId)
    const commentsData = await getComments(chapterId)

    loadTranslations({
      currentDocument: translationDocument,
      chapterData,
      translationsData,
      commentsData,
      setIsLoading: setTranslationViewLoading
    })

    return translationDocument
  }

  const onExport = () => {
    setExportModal(true)
  }

  createEffect(() => {
    const isLoading = isTranslationViewLoading() || isCompletedViewLoading()
    setLoadingState({
      loading: isLoading
    })
  })
  
  return (
    <div class={styles.completedView}>
      <CanvasView
        settings={{
          mode: 'translation',
          readOnly: true
        }}
        onReady={onTranslationDocumentReady}
        invisible={true}
      />
      <CanvasView
        settings={{
          mode: 'typesetting',
          hideControls: true,
          readOnly: true
        }}
        onReady={onTypesettingDocumentReady}
      />
      <div class={styles.sideView}>
        <Show when={chapter()}>
          {chapter => (
            <SidebarHeader
              chapter={chapter()}
              mode='validation'
            />
          )}
        </Show>
        <ExportModal
          open={showExportModal()}
          setOpen={setExportModal}
          chapterId={chapterId}
        />
        <div class={styles.exportButtonContainer}>
          <Button
            onClick={onExport}
            icon={ExportIcon}
          >
            Exporter le chapitre
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CompletedView